import React, { Component } from 'react';

import { Config } from './Config';

import GameList from './components/GameList';
import GameInfo from './components/GameInfo';
import GamePlay from './components/GamePlay';

import { Error } from './components/Common';

import SignInForm from './components/SignInForm';
import SignUpForm from './components/SignUpForm';

import TopBar from './components/TopBar';

import './App.css';

import { Container } from 'reactstrap';

class App extends Component {

  constructor(props) {

    super(props);

    this.state = {
      view: '', data: '', form: '',
      signedIn: !!localStorage.getItem('token'),
      userName: '',
	  };

    this.handleAuth = this.handleAuth.bind(this);
    this.cancelForm = this.cancelForm.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.renderView = this.renderView.bind(this);

  }

  componentDidMount() {
    if (this.state.signedIn) {
      fetch(`${Config.ORIGIN}/core/current_user/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`,
        },
      }).then((response) => {
        if (response.status === 401)
          localStorage.removeItem('token');
        return response.json();
      }).then((json) => {
        if (json.username)
          this.setState({userName: json.username});
        else
          this.setState({signedIn: false});
        console.log(json);
      },
      (error) => {
        console.log(error);
      });
    }
  }

  componentWillUnmount() {
	  //TODO: Cleanup.
  }

  handleAuth(userName='') {
    const signedIn = (!!userName && !!localStorage.getItem('token'));
    this.setState({userName: userName, signedIn: signedIn, form: ''});
  }

  cancelForm() {
    this.setState({form: ''});
  }

  renderForm(form) {
    this.setState({form: form});
  }

  renderView(view, data) {
    this.setState({view: view, data: data});
  }

  render() {

    let view = '';

    if (!!this.state.form) {
      switch (this.state.form) {
        case 'signin':
          view = (
            <SignInForm
              onSignIn={this.handleAuth}
              onSignUp={() => this.renderForm('signup')}
              onCancel={this.cancelForm}
            />
          );
          break;
        case 'signup':
          view = (
            <SignUpForm
              onSignUp={this.handleAuth}
              onSignIn={() => this.renderForm('signin')}
              onCancel={this.cancelForm}
            />
          );
          break;
        default:
          // Invalid form.
          break;
      }
    }

    const data = this.state.data;

    if (!view) {
      switch (this.state.view) {
        case 'info':
          view = (
            <GameInfo
              id={data.id}
              renderForm={this.renderForm}
              renderView={this.renderView}
            />
          );
          break;
        case 'play':
          view = (
            <GamePlay
              id={data.id}
              data={data}
              renderForm={this.renderForm}
              renderView={this.renderView}
            />
          );
          break;
        case 'error':
          view = <Error message={data.toString()} />;
          break;
        default:
          view = (
            <GameList
              renderForm={this.renderForm}
              renderView={this.renderView}
            />
          );
          break;
      }
    }

    return (
      <Container style={{ maxWidth: '640px' }}>
        <TopBar
          navTitle='Trivia Game'
          userName={this.state.userName}
          signedIn={this.state.signedIn}
          renderForm={this.renderForm}
          renderView={this.renderView}
          onSignOut={this.handleAuth}
        />
        {view}
      </Container>
    );

  }

}

export default App;
