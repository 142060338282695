import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Config } from '../Config';

import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';

class SignUpForm extends Component {
  
  constructor(props) {

    super(props);

    this.state = {
      username: '',
      password: '',
    };

    this.handleChange = this.handleChange.bind(this);

  }

  handleChange(event) {
    
    const name = event.target.name;
    const value = event.target.value;
    
    /*
    this.setState((oldState) => {
        const newState = { ...oldState };
        newState[name] = value;
        return newState;
    });
    /*/
    this.setState({[name]: value});
    // */
    
  };

  handleSignUp(event, data) {

    event.preventDefault();

    fetch(`${Config.ORIGIN}/core/users/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    }).then((response) => {
      console.log(response.status);
      return response.json();
    }).then((json) => {
      if (json.token) {
        console.log(json);
        localStorage.setItem('token', json.token);
        this.props.onSignUp(json.username);
      }
    },
    (error) => {
      console.log('handleSignUp');
      console.log(error);
    });
	
  }

  render() {
    return (
      <Form onSubmit={e => this.handleSignUp(e, this.state)}>
        <Card>
          <CardHeader>Sign Up</CardHeader>
          <CardBody>
          <FormGroup>
            <Label htmlFor="username">Username</Label>
            <Input
              type="text"
              name="username"
              value={this.state.username}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="password">Password</Label>
            <Input
              type="password"
              name="password"
              value={this.state.password}
              onChange={this.handleChange}
            />
          </FormGroup>
          </CardBody>
          <CardFooter>
            <Button color='success'>Sign Up</Button>{' '}
            <Button color='link' onClick={this.props.onCancel}> Back </Button>
          </CardFooter>
        </Card>
        {!!this.props.onSignIn && (
          <Alert color='light' className='text-center'>
            Already have an account, <a onClick={this.props.onSignIn} href='#' className='alert-link'>Sign In</a> here.
          </Alert>
        )}
      </Form>
    );
  }
}

SignUpForm.propTypes = {
  onSignUp: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSignIn: PropTypes.func,
};

SignUpForm.defaultProps = {
  onSignIn: null,
};

export default SignUpForm;
