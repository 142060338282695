import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Config } from '../Config';

import { Load } from '../components/Common';

import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardSubtitle,
  CardText,
  CardTitle,
  Collapse,
  ListGroup,
  ListGroupItem,
  ListGroupItemText,
} from 'reactstrap';

class GameInfo extends Component {

  constructor(props) {
    
    super(props);

    this.state = {id: props.id, data: props.data, toggle: false};

  }

  componentDidMount() {
    if (!this.state.data.id) {
      fetch(
        `${Config.ORIGIN}/api/info/${this.state.id}/`
      ).then((res) => res.json()).then((json) => {
        console.log(json);
        this.setState({data: json});
      },
      (error) => {
        console.log(error);
        this.props.renderView('error', error);
      });
    }
  }
  
  renderAnswers(answers) {
    let i = 0;
    return (
      <ListGroup className='mt-0 mb-2' flush={true}>
        {answers.map((a) => {
          return (
            <ListGroupItem className='px-2 py-1' key={a.id.toString()}>
              {String.fromCharCode(97 + i++)}: {a.label}{' '}
              {a.correct && <Badge color='success' pill>&#x2714;</Badge>}
              <div className='float-right'>
                <Badge color='info' pill>{a.count}</Badge>
              </div>
            </ListGroupItem>
          );
        })}
      </ListGroup>
    );
  }

  renderQuestions(questions) {
    let i = 1;
    return (
      <ListGroup>
        {questions.map((q) => {
          return (
            <ListGroupItem className='p-1' key={q.id.toString()}>
              <ListGroupItemText className='p-1 my-1'>{i++}. {q.text}</ListGroupItemText>
              {this.renderAnswers(q.answers)}
            </ListGroupItem>
          );
        })}
      </ListGroup>
    );
  }

  render() {
  
    const data = this.state.data;
    const func = this.props.renderView;
    const open = this.state.open;
  
    if (!data.id) {
      return <Load />;
    }
    console.log(data);
    return (
      <Card>
        <CardHeader className='p-2'>
          <CardTitle>{data.name}</CardTitle>
          {data.description && (<CardSubtitle>{data.description}</CardSubtitle>)}
        </CardHeader>
        <Collapse isOpen={!open}>
          <CardBody className='m-0 p-0'>
            <ListGroup>
              <ListGroupItem className='p-2' key='published_on'>
                Published: {data.published_on.replace('T', ' ').replace(/:[^:]+$/, '')}
              </ListGroupItem>
              <ListGroupItem className='p-2' key='min_players'>
                Min Players: {data.min_players}
              </ListGroupItem>
              <ListGroupItem className='p-2' key='num_players'>
                # of Players: {data.num_players}
              </ListGroupItem>
              <ListGroupItem className='p-2' key='state'>
                Status: <Badge color='info' className='text-capitalize'>{data.state}</Badge>
              </ListGroupItem>
              {!!data.winners && (
                <ListGroupItem className='p-2' key='winners'>
                  Winner{data.winners.length > 1 ? 's' : ''}:
                  {data.winners.map((w) => <Badge color='success' className='ml-1 text-capitalize'>{w}</Badge>)}
                </ListGroupItem>
              )}
            </ListGroup>
          </CardBody>
        </Collapse>
        {!!data.questions && (
          <>
            <Button outline color='secondary' className='m-1 p-1' onClick={() => this.setState({open: !open})}> {open ? 'Hide' : 'Show' } Statistics </Button>
            <Collapse isOpen={open}>
              <CardHeader className='p-2'>
                <CardText>
                  Questions and Answers
                </CardText>
              </CardHeader>
              <CardBody className='m-0 p-0'>
                {this.renderQuestions(data.questions)}
              </CardBody>
              <CardFooter className='p-2'>
                <CardText className='mb-0'>
                  <Badge color='success' pill>&#x2714;</Badge> = Correct Answer
                </CardText>
                <CardText className='mt-0'>
                  <Badge color='info' pill>#</Badge> = Number of Responses
                </CardText>
              </CardFooter>
            </Collapse>
          </>
        )}
        <CardFooter className='p-2'>
          {data.state === 'published' && (
				    <Button color='success' onClick={() => func('play', data)}> Join </Button>
				  )}
          <Button color='link' onClick={() => func('', {})}> Back </Button>{' '}
        </CardFooter>
      </Card>
    );

  }

}

GameInfo.propTypes = {
  id: PropTypes.number.isRequired,
  renderForm: PropTypes.func.isRequired,
  renderView: PropTypes.func.isRequired,
  data: PropTypes.object,
}

GameInfo.defaultProps = {
  data: {},
}

export default GameInfo;
