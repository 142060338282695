import React from 'react';
import PropTypes from 'prop-types';

import { Badge, ListGroupItem } from 'reactstrap';

function Answer(props) {

  const answer = props.answer;
  const disabled = props.disabled;

  const BADGES = {
	right: String.fromCharCode(0x2714),
	wrong: String.fromCharCode(0x2716),
  }

  if (disabled) {
	return (
	  <ListGroupItem 
	  	className='my-2'
		color={props.color}
	  >
		{answer.label} {!!props.badge && (
		  <Badge color={props.color} pill>{BADGES[props.badge]}</Badge>
		)}
		{(props.count !== null) && (
		  <div className='float-right'>
			<Badge color='info'>{props.count}</Badge>
		  </div>
		)}
	  </ListGroupItem>
	);
  }
  else {
	return(
	  <ListGroupItem
	  	className='mt-2 mb-2'
	  	color={props.color}
		onClick={() => props.onChange(answer.id)}
		tag='button'
		action
	  >
		{answer.label}
	  </ListGroupItem>
		
	);

  }

}

Answer.propTypes = {
  answer: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  color: PropTypes.string,
  badge: PropTypes.string,
  count: PropTypes.number,
};

Answer.defaultProps = {
  color: 'light',
  badge: '',
  count: null,
}

export default Answer;
