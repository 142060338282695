import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Alert } from 'reactstrap';

class Timer extends Component {

  constructor(props) {

	  super(props);

	  this.state = {
      ticks: props.ticks,
    };

    this.timerId = null;

  }

  componentDidMount() {
    console.log('Timer.componentDidMount()');
    this.timerId = setInterval(() => this.tick(), 1000);
    this.setState({ticks: this.props.ticks});
  }

  componentWillUnmount() {
    if (!!this.timerId) {
      clearInterval(this.timerId);
      this.timerId = null;
    }
  }

  tick() {
    const ticks = this.state.ticks - 1;
	  if (ticks <= 0) {
      clearInterval(this.timerId);
      this.timerId = null;
    }
	  this.setState({ticks: ticks});
  }

  render() {

    if (this.props.label) {
      return (<Alert color='warning' className='text-center'>{this.props.label} {this.state.ticks}</Alert>);
    }
    else {
      return (<Alert color='warning' className='text-center'>{this.state.ticks}</Alert>);
    }

  }

}

Timer.propTypes = {
  ticks: PropTypes.number.isRequired,
  label: PropTypes.string,
}

Timer.defaultProps = {
  label: '',
}

export default Timer;
