//import React from 'react';

const WS_PROTO = (window.location.protocol === 'https:') ? 'wss:' : 'ws:';

const DevConfig = {
  ORIGIN: `${window.location.protocol}//${window.location.hostname}:8000`,
  WS_URL: `${WS_PROTO}//${window.location.hostname}:8000/ws/play/`,
}

const ProdConfig = {
  ORIGIN: `${window.location.protocol}//${window.location.host}`,
  WS_URL: `${WS_PROTO}//${window.location.host}/ws/play/`,
}

export const Config = (process.env.NODE_ENV === 'development') ? DevConfig : ProdConfig;

