import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Config } from '../Config';

import { Load } from '../components/Common';

import {
  Badge,
  Button,
  ListGroup,
  ListGroupItemHeading,
  ListGroupItem,
  ListGroupItemText,
} from 'reactstrap';

class GameList extends Component {

  constructor(props) {
	
	super(props);

	this.state = {data: props.data};

  }

  componentDidMount() {
	//console.log('GameList.componentDidMount()');
	if (!this.state.data.length) {
	  fetch(
		`${Config.ORIGIN}/api/list/`
	  ).then((res) => res.json()).then((json) => {
		this.setState({data: json});
	  },
	  (error) => {
		this.props.renderView('error', error);
	  });
	}
  }

  render() {

	//console.log('GameList.render()');

	const data = this.state.data;
	const func = this.props.renderView;

	if (!data.length) {
	  return <Load />;
	}

	return (
	  <ListGroup>
		<ListGroupItem className='p-2' active>Games</ListGroupItem>
		{data.map((game) => {
		return (
		  <ListGroupItem className='p-2' key={game.id.toString()}>
			<ListGroupItemHeading>{game.name}</ListGroupItemHeading>
			<div className='float-right'>
			  {game.state === 'published' && (
				<Button color='success' onClick={() => func('play', game)}> Join </Button>
			  )}
			  {game.state !== 'published' && (
				<Button color='info' onClick={() => func('info', game)}> View </Button>
			  )}
			</div>
			<ListGroupItemText className='mb-1'>
			  Published: {game.published_on.split('T')[0]}
			  <br />
			  Min Players: {game.min_players}
			  <br />
			  Status:{' '}
			  <Badge className='text-capitalize' color={game.state === 'published' ? 'success' : 'info'}>
			  	{game.state}
			  </Badge>
			</ListGroupItemText>
		  </ListGroupItem>
		);
		})}
	  </ListGroup>
	);

  }

}

GameList.propTypes = {
  renderForm: PropTypes.func.isRequired,
  renderView: PropTypes.func.isRequired,
  data: PropTypes.array,
}

GameList.defaultProps = {
	data: [],
}

export default GameList;
