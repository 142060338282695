import React from 'react';
import PropTypes from 'prop-types';

import Answer from '../components/Answer';

import { ListGroup } from 'reactstrap';

function Question(props) {
  
  const question = props.question;

  function handleAnswer(value) {
	if (value > 0 && !!props.onAnswer) {
	  props.onAnswer(question.id, value);
	}
  }

  const correct = props.correct;
  const selected = props.selected;
  
  const counts = !!correct ? props.counts : null;
  
  const disabled = !!selected || !!correct;

  return (
	<>
	  <p>{question.level}. {question.text}</p>
	  <ListGroup>
		{props.answers.map((answer) => {
		  const count = !!counts ? (counts[answer.id] || 0) : null;
		  let color = (answer.id === selected) ? 'primary' : 'light';
		  let badge = '';
		  if (correct > 0) {
			if (answer.id === selected) {
			  color = (answer.id === correct) ? 'success' : 'danger';
			  badge = (answer.id === correct) ? 'right' : 'wrong';
			}
			else {
			  if (answer.id === correct)
				color = 'success';
			}
		  }
		  return (
			<Answer
			  key={answer.id.toString()}
			  answer={answer}
			  onChange={handleAnswer}
			  disabled={disabled}
			  color={color}
			  badge={badge}
			  count={count}
			/>
		  );
		})}
	  </ListGroup>
	</>
  );

}

Question.propTypes  = {
  question: PropTypes.object.isRequired,
  answers: PropTypes.array.isRequired,
  selected: PropTypes.number,
  onAnswer: PropTypes.func,
  counts: PropTypes.number, 
  correct: PropTypes.number,
};

Question.defaultProps = {
  selected: 0,
  onAnswer: null,
  counts: null,
  correct: 0,
};

export default Question;
