import React from 'react';
import PropTypes from 'prop-types';

import { Alert, Spinner } from 'reactstrap';

export function Error(props) {
  return (<Alert color='danger' className='mt-2'>Error! {props.message}</Alert>)
}

Error.propTypes = {
  message: PropTypes.string.isRequired,
}

export function Info(props) {
  return (<Alert color={props.color} className='mt-2'>{props.message}</Alert>);
}

Info.propTypes = {
  message: PropTypes.string.isRequired,
  color: PropTypes.string,
}

Info.defaultProps = {
  color: 'light',
}

export function Load(props) {
  return(
    <div className='text-center mt-4 nb-4'>
      <Spinner color={props.color} />
      <h6 className='mt-2'>{props.label}</h6>
    </div>
  );
}

Load.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
}

Load.defaultProps = {
  color: 'info',
  label: 'Loading...',
}

export function Warn(props) {
  return (<Alert color='warning' className='mt-2'>Warning! {props.message}</Alert>)
}

Warn.propTypes = {
  message: PropTypes.string.isRequired,
}
