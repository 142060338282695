import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Config } from '../Config';

import Question from '../components/Question';
import Timer from '../components/Timer';

import { Error, Info, Load } from '../components/Common';

import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  UncontrolledAlert,
} from 'reactstrap';

class GamePlay extends Component {

  constructor(props) {

    super(props);
    
    console.log('GamePlay.constructor()');

    this.state = {
      id: props.id,
      data: props.data,
      action: '',
      command: '',
      content: {},
      message: '',
      selected: 0,
      status: null,
      timeout: 0,
    };

    this.wsocket = null;
    this.timerId = null;

    this.sendAnswer = this.sendAnswer.bind(this);

  }

  componentDidMount() {
    
    console.log('GamePlay.componentDidMount()');
    
    fetch(`${Config.ORIGIN}/api/join/${this.state.id}/`, {
      headers: {
        Authorization: `JWT ${localStorage.getItem('token')}`,
      },
    }).then((response) => {
      if (response.status === 401)
        this.props.renderForm('signin');
      return response.json();
    }).then((json) => {
      console.log(json);
      this.setState({command: 'start', content: json});
      this.startGame();
    },
    (error) => {
      console.log(error);
      this.setState({command: 'error', content: error});
    });

  }

  componentWillUnmount() {
    console.log('GamePlay.componentWillUnmount()');
    if (!!this.wsocket) {
      this.wsocket.close();
    }
  }

  setTimeout(timeout) {
    this.timerId = setTimeout(() => this.handleTimeout(), timeout * 1000);
  }

  handleTimeout() {
    this.setState({timeout: 0});
    this.timerId = null;
  }

  clearTimeout() {
    if (!!this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = null;
    }
  }

  startGame() {

    console.log('GamePlay.startGame()');

    if (!this.wsocket) {
      const token = localStorage.getItem('token');
      const wsurl = `${Config.WS_URL}${this.props.id}/${token}`;
      this.wsocket = new WebSocket(wsurl);
    }

    const wsocket = this.wsocket;

    wsocket.onopen = () => {
      console.log('WebSocket connected.');
    };

    wsocket.onmessage = (event) => {
      this.onMessage(event);
    }
    
    wsocket.onclose = () => {
      console.log('WebSocket disconnected.');
    };

  }

  onMessage(event) {

    console.log('GamePlay.onMessage()');

    const payload = JSON.parse(event.data);
      
    console.log(payload);
    
    if (payload.action === 'command') {

      const command = payload.command;
      const content = payload.content;

      let selected = 0; let timeout = 0;

      if (command === 'result') {
        selected = this.state.selected;
        if (!!content.over && content.delay > 0) {
          setTimeout(() => this.props.renderView('info', {
            id: this.state.id,
          }), content.delay * 1000);
          content.delay = 0;
        }
      }
      else {
        timeout = content.timeout;
        if (timeout > 0)
          this.setTimeout(timeout);
        else
          this.clearTimeout();
      }

      this.setState({
        action: '',
        command: command,
        content: content,
        message: '',
        selected: selected,
        status: null,
        timeout: timeout,
      })
      
    }
    else {

      this.setState({
        action: payload.action,
        message: payload.message,
        status: payload.status,
      });

    }

  }

  sendAnswer(question, answer) {
    
    console.log('GamePlay.sendAnswer()');

    console.log(this.state);

    if (this.state.timeout > 0 && this.state.command === 'question') {
      
      const content = this.state.content;
      
      if (content.question && content.question.id === question) {
        
        this.clearTimeout();
        
        if (!!this.wsocket) {
          this.wsocket.send(JSON.stringify({
            answer: {
              question: question,
              answer: answer,
            },
          }));
        }

        this.setState({
          selected: answer,
          timeout: 0,
        });

      }

    }

  }

  render() {
    
    console.log('GamePlay.render()');

    const state = this.state;
    const props = this.props;

    const data = state.data;
    const func = props.renderView;

    const selected = state.selected;

    const command = state.command;
    const content = state.content;
    const timeout = state.timeout;

    const COLORS = {
      danger: 'danger',
      dark: 'dark',
      error: 'danger',
      info: 'info',
      message: 'info',
      light: 'light',
      primary: 'primary',
      secondary: 'secondary',
      success: 'success',
      warning: 'warning',
    };
  
    let view = null;
    
    switch (command) {
      case 'info':
        view = <Info message={content.message} />;
        break;
      case 'wait':
        view = <Timer ticks={content.delay} label={content.text} />;
        break;
      case 'question':
        view = (
          <>
            <Question
              question={content.question}
              answers={content.answers}
              onAnswer={this.sendAnswer}
              selected={selected}
              correct={0}
            />
            {!!selected && <Info message='Answer sent, waiting for reply...' />}
            {!!timeout && (
              <Timer key={content.question.id.toString()}
                ticks={timeout}
                label='Timeout in:'
              />
            )}
          </>
        );
        break;
      case 'result':
        view = (
          <>
            <Question
              question={content.question}
              answers={content.answers}
              counts={content.counts}
              correct={content.answer.id}
              selected={selected}
            />
            {!!content.message && <Info message={content.message} />}
            {!!content.delay && (
              <Timer
                ticks={content.delay}
                label={content.text}
              />
            )}
          </>
        );
        break;
      case 'start':
        view = <Load label='Starting...' />;
        break;
      case 'error':
        view = <Error message={content.message} />;
        break;
      default:
        view = <Load label='Connecting...' />;
        break;
    }

    let info = null;

    if (!!state.action) {
      const action = state.action;
      if (action === 'status') {
        const status = state.status;
        info = (
          <>
            {!!state.message && (
              <UncontrolledAlert color='info' className='text-center'>
                {state.message}
              </UncontrolledAlert>
            )}
            <Alert color={status.num_players >= status.min_players ? 'success' : 'warning'} className='text-center'>
              {`${status.num_players} Player(s) joined out of ${status.min_players} required.`}
            </Alert>
          </>
        );
      }
      else {
        info = (
          <Alert color={COLORS[action] || 'info'} className='text-center'>
            {state.message}
          </Alert>
        );
      }
    }

    return (
      <Card>
        {!!data.name && (
          <CardHeader>
            {data.name}
          </CardHeader>
        )}
        <CardBody>
          {view}
        </CardBody>
        {!!info && (
          <CardBody>
            {info}
          </CardBody>
        )}
        <CardFooter>
          <Button color='link' onClick={() => func('', {})}> Back </Button>
        </CardFooter>
      </Card>
    );

  }

}

GamePlay.propTypes = {
  id: PropTypes.number.isRequired,
  renderForm: PropTypes.func.isRequired,
  renderView: PropTypes.func.isRequired,
  data: PropTypes.object,
}

GamePlay.defaultProps = {
  data: {},
}

export default GamePlay;
