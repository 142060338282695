import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Config } from '../Config';

import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';

class SignInForm extends Component {
  
  constructor(props) {

    super(props);

    this.state = {
      username: '',
      password: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSignIn = this.handleSignIn.bind(this);

  }

  handleChange(event) {
    
    const name = event.target.name;
    const value = event.target.value;
    
    /*
    this.setState((oldState) => {
        const newState = { ...oldState };
        newState[name] = value;
        return newState;
    });
    /*/
    this.setState({[name]: value});
    // */

  };

  handleSignIn(event) {
	
	  event.preventDefault();
	
    fetch(`${Config.ORIGIN}/token-auth/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(this.state),
    }).then((response) => {
      console.log(response.status);
      return response.json();
    }).then((json) => {
      if (json.token) {
        console.log(json);
        localStorage.setItem('token', json.token);
        this.props.onSignIn(json.user.username);
      }
    },
    (error) => {
      console.log('handleSignIn');
      console.log(error);
    });
	
  }

  render() {
    return (
      <Form onSubmit={this.handleSignIn}>
        <Card>
          <CardHeader>Sign In</CardHeader>
          <CardBody>
            <FormGroup>
              <Label htmlFor="username">Username</Label>
              <Input
                type="text"
                name="username"
                value={this.state.username}
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="password">Password</Label>
              <Input
                type="password"
                name="password"
                value={this.state.password}
                onChange={this.handleChange}
              />
            </FormGroup>
          </CardBody>
          <CardFooter>
            <Button color='success'>Sign In</Button>{' '}
            <Button color='link' onClick={this.props.onCancel}> Back </Button>
          </CardFooter>
        </Card>
        {!!this.props.onSignUp && (
          <Alert color='light' className='text-center'>
            Don't have an account, <a onClick={this.props.onSignUp} href='#' className='alert-link'>Sign Up</a> here.
          </Alert>
        )}
      </Form>
    );
  }
}

SignInForm.propTypes = {
  onSignIn: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSignUp: PropTypes.func,
};

SignInForm.defaultProps = {
  onSignUp: null,
};

export default SignInForm;
