import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  NavbarText
} from 'reactstrap';

class TopBar extends Component {

  constructor(props) {

    super(props);

    this.state = {isOpen: false};

    this.handleSignOut = this.handleSignOut.bind(this);
    this.toggleNav = this.toggleNav.bind(this);

  }

  handleSignOut() {
    localStorage.removeItem('token');
    this.props.onSignOut();
  }

  toggleNav(e) {
    this.setState({isOpen: !this.state.isOpen});
  }

  render() {

    const props = this.props;
    
    const signedInNav = (
      <Nav className="mr-auto" navbar>
        {!!props.userName && (<NavbarText>{props.userName}</NavbarText>)}
        <NavItem>
          <NavLink href="#" onClick={this.handleSignOut}>Sign Out</NavLink>
        </NavItem>
      </Nav>
    );
  
    const signedOutNav = (
      <Nav className="mr-auto" navbar>
        <NavItem>
          <NavLink href='#' onClick={() => props.renderForm('signin')}>Sign In</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href='#' onClick={() => props.renderForm('signup')}>Sign Up</NavLink>
        </NavItem>
      </Nav>
    );
  
    return (
      <div>
        <Navbar color="faded" light expand="md">
          {props.navTitle && (
            <NavbarBrand href='#' onClick={() => props.renderView('', {})}>
              {props.navTitle}
            </NavbarBrand>
          )}
          <NavbarToggler onClick={this.toggleNav} />
          <Collapse isOpen={this.state.isOpen} navbar>
            {props.signedIn ? signedInNav : signedOutNav}
          </Collapse>
        </Navbar>
      </div>
    );
  
  }

}

TopBar.propTypes = {
  signedIn: PropTypes.bool.isRequired,
  renderForm: PropTypes.func.isRequired,
  renderView: PropTypes.func.isRequired,
  onSignOut: PropTypes.func.isRequired,
  navTitle: PropTypes.string.isRequired,
  userName: PropTypes.string,
};

TopBar.defaultProps = {
  userName: '',
}

export default TopBar;
